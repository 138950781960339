import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-534b4ef7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vp-registration-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_h_group = _resolveComponent("a-h-group")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tab_menu = _resolveComponent("tab-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.hideHeader)
      ? (_openBlock(), _createBlock(_component_a_h_group, {
          key: 0,
          heading: _ctx.$t('registration.group.heading'),
          subheading: _ctx.$t('registration.group.subheading')
        }, null, 8, ["heading", "subheading"]))
      : _createCommentVNode("", true),
    (!_ctx.hideHeader)
      ? (_openBlock(), _createBlock(_component_tab_menu, {
          key: 1,
          class: "vp-tabmenu",
          model: _ctx.tabs
        }, {
          item: _withCtx(({ item }) => [
            _createVNode(_component_router_link, {
              class: "block p-menuitem-link text-center h-full",
              to: item.to
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }, 8, ["model"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}