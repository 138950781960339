
import TabMenu from "primevue/tabmenu";
import AHGroup from "@/components/atoms/a-hgroup.vue";
import { REGISTRATION_PATHS, ROUTE_PATHS } from "@/router/config";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    AHGroup,
    TabMenu,
  },
  computed: {
      tabs(): { label: string, to: string }[] {
        return [
        {
          label: this.$t("registration.group.tab"),
          to: `/${this.$i18n.locale }/${ROUTE_PATHS.REGISTRATION}/${REGISTRATION_PATHS.GROUP}`,
        },
        {
          label: this.$t("registration.individual.tab"),
          to: `/${this.$i18n.locale}/${ROUTE_PATHS.REGISTRATION}/${REGISTRATION_PATHS.SINGLE}`,
        },
      ];
    },
    hideHeader(): boolean {
      return this.$route.meta?.hideHeader as boolean;
    }
  }
});
